window.HockeyStack = (() => {
  'use strict';

  const HockeyStack = {};

  // VARIABLES
  let serverURL = 'https://data.hockeystack.com';
  let serverPath = '/send';
  const inactivityTime = 10;
  const eventListenerList = [];
  const actionLog = [];

  const hsScript = document.currentScript || document.querySelector('script[src*="hockeystack.min.js"]');
  const apiKey = hsScript.getAttribute('apikey') || hsScript.getAttribute('data-apikey');
  let intervalStart = hsScript.getAttribute('data-interval-start') * 1000 || 5000;
  const intervalIncrement = hsScript.getAttribute('data-interval-increment') * 1000 || 2000;
  const isFingerprint = !!(hsScript.getAttribute('cookieless') || hsScript.getAttribute('data-cookieless') || hsScript.getAttribute('data-cookieless') === '');
  const crossDomain = !!(hsScript.getAttribute('cross-domain') || hsScript.getAttribute('data-cross-domain')) && !isFingerprint;
  let autoIdentify = !!(hsScript.getAttribute('auto-identify') || hsScript.getAttribute('data-auto-identify'));
  const allowIframe = true; // !!(hsScript.getAttribute('allow-iframe') || hsScript.getAttribute('data-allow-iframe'));
  const onlyIdentify = !!(hsScript.getAttribute('only-identify') || hsScript.getAttribute('data-only-identify'));
  const privacyMode = !!(
    hsScript.getAttribute('privacy-mode') ||
    hsScript.getAttribute('data-privacy-mode') ||
    hsScript.getAttribute('data-privacy-mode') === ''
  );
  if (hsScript.getAttribute('data-server-url')) serverURL = hsScript.getAttribute('data-server-url');
  const debugMode = window.location.search.includes('hsdebug=true');
  HockeyStack.cookieless = isFingerprint;

  if (!apiKey) {
    console.error('HockeyStack: No API key provided');
    return;
  }

  // disable HockeyStack for specific API keys
  if (['d7a8d720de58efe016822c1390cebb', '3f5faca79c311a7348a04aa7389bcb'].includes(apiKey)) {
    return;
  }

  if (['695accfb6c874ac0d46cf9c7b3303f', '78f842f2350d245dd06df65bfa49b9', '82ee9e4a8b15fc058046998d5e9fbe'].includes(apiKey)) autoIdentify = true;
  // currently unavailable
  if ([].includes(apiKey)) {
    serverURL = 'https://data.hockeystack.com';
    serverPath = '/send-fast';
  }

  if (debugMode) console.log('HockeyStack: debug mode is on');

  let inactivityCounter = 0;
  let isInactive = false;
  let scrollMax = 0;
  let lastActiveDate = new Date();
  let isDevicePhone;
  let exitedPage = false; // sometimes two consequent endSession() calls are made from event listeners in phones
  let formsOnPage = [];
  let identity;

  if (hsScript.getAttribute('data-identity')) identity = hsScript.getAttribute('data-identity');

  const documentAlias = document;
  const navigatorAlias = navigator;
  const windowAlias = window;

  let customerObject;
  let userObject;
  let originalUUID; // for cookieless, remove the original cookie and store the original uuid for the next req

  const getCurrentURL = () => {
    let url = windowAlias.location.origin + windowAlias.location.pathname + windowAlias.location.search + windowAlias.location.hash;

    return url.toLowerCase();
  }

  let globalCurrentPage = getCurrentURL();

  const supportsSendBeacon = () => typeof navigatorAlias === 'object' &&
    typeof navigatorAlias.sendBeacon === 'function' &&
    typeof Blob === 'function' &&
    apiKey !== '50fcf52eca5476546b54230ccbaea2';

  const sendReqViaBeacon = (path, requestObj, skipError) => {
    const isSupported = supportsSendBeacon();

    if (debugMode) console.log('Send request via beacon', JSON.stringify(requestObj), isSupported);

    if (!isSupported) {
      sendReqViaXHR(path, requestObj);
      return false;
    }

    let success = false;
    const data = new Blob([JSON.stringify(requestObj)], { type: 'application/json' });

    const serverUrl = `${serverURL}${path}`;

    try {
      success = navigatorAlias.sendBeacon(serverUrl, data);
    } catch (err) {
      if (debugMode) console.log('Error while sending request via beacon: ', err.message);

      if (!skipError) sendErrorsToServer(err, { operation: 'sendReqViaBeacon', requestObj, serverUrl });
      success = false;
    }

    if (debugMode) console.log('Sent request via beacon', success);

    if (!success) sendReqViaXHR(path, requestObj, skipError);
    return success;
  };

  const sendReqViaXHR = (path, requestObj, skipError) => {
    if (actionLog.length === 0) return false;

    let success = true;

    const serverUrl = `${serverURL}${path}`;
    const request = new XMLHttpRequest();

    try {
      request.open('POST', serverUrl, true);
      request.setRequestHeader('Content-Type', 'text/plain; charset=UTF-8');
      request.send(JSON.stringify(requestObj));

      request.onreadystatechange = () => true;
    } catch (err) {
      if (!skipError) sendErrorsToServer(err, { operation: 'sendReqViaBeacon', requestObj, serverUrl });
      success = false;
    }

    return success;
  };

  const sendDebugToServer = (purpose, metadata = {}) => {
    const actionLogCopy = JSON.parse(JSON.stringify(actionLog));
    const requestObj = { purpose, customerObject, userObject, actionLog: actionLogCopy, metadata };
    sendReqViaBeacon('/send-debug', requestObj);
  }

  const sendErrorsToServer = (err, metadata) => {
    const errorObj = {
      error: err.message,
      apiKey,
      page: window.location.href,
      metadata
    };
    sendReqViaBeacon('/send-error', errorObj, true);
  };

  try {
    HockeyStack.init = function (apiKey) {
      if (isBot() || navigatorAlias.webdriver) return; // if bot, don't run the script
      try {
        if (!allowIframe && window.parent.location !== window.location) return; // if in iframe, don't run the script
      } catch (err) {
        // error is most likely due to cors. don't run the script.
        return;
      }

      HockeyStack.apiKey = apiKey;

      lastActiveDate = new Date();
      isDevicePhone = getDevice(navigatorAlias.userAgent.toLowerCase()) === 'phone';

      // check if revisiting user (added extra checks for cookieless)
      if (!(getCookie('hs_uuid') || isFingerprint)) setCookie('hs_uuid', getId(), 365);
      else if (getCookie('hs_uuid') && isFingerprint) {
        originalUUID = getCookie('hs_uuid');
        removeCookie('hs_uuid');
      }

      customerObject = {
        website: window.location.hostname,
        apiKey: HockeyStack.apiKey,
        isFingerprint,
        debugMode,
        serverPath,
        serverURL,
        version: '1.3.168'
      };
      userObject = getUserProperties();

      identifyThroughURL();
      trackGravityForms();

      createAction('enter-page', null);
      sendActions('beacon');

      try {
        trackContentSchema();
      } catch (err) {
        sendErrorsToServer(err, { operation: 'trackContentSchema' });
      }

      HockeyStack.trackSession();
      HockeyStack.sendIntervalData();
    };

    HockeyStack.sendIntervalData = function () {
      setTimeout(() => {
        if (documentAlias.hasFocus()) {
          intervalStart += intervalIncrement;
          HockeyStack.endSession(true);
          HockeyStack.sendIntervalData();
        } else {
          HockeyStack.sendIntervalData();
        }
      }, intervalStart);
    };

    HockeyStack.endSession = function (isMid) {
      let scrollDepth = (!hasVerticalScroll()) ? 100 : parseInt((scrollMax / (getDocHeight() - getViewportHeight())) * 100);
      if (scrollDepth > 100) scrollDepth = 100;

      if (isInactive && (typeof isMid === 'undefined' || !isMid)) {
        createAction('scroll-depth', null, scrollDepth);
        createAction('end-session');
        sendActions('beacon');
        return;
      }

      if (!isInactive && typeof isMid !== 'undefined' && isMid) {
        createAction('scroll-depth', null, scrollDepth);
        sendActions('beacon');
        return;
      }

      let nextSiteURL = documentAlias.activeElement.href;
      if (sameSite(nextSiteURL) && globalCurrentPage !== nextSiteURL) {
        nextSiteURL = nextSiteURL.split('#')[0];
        const currentURL = getCurrentURL();
        if (nextSiteURL.startsWith('http') && currentURL !== nextSiteURL) { // && currentURL === lastURL
          createAction('scroll-depth', null, scrollDepth);
          sendActions('beacon');
        }
      } else {
        createAction('scroll-depth', null, scrollDepth);
        createAction('end-session');
        sendActions('beacon');
      }
    };

    HockeyStack.trackSession = () => {
      isInactive = false;

      addEvent(windowAlias, 'pagehide', () => {
        if (!exitedPage) {
          exitedPage = true;
          HockeyStack.endSession();
        }
      });

      if (isDevicePhone) {
        addEvent(windowAlias, 'pageshow', event => {
          if (event.persisted) HockeyStack.init(HockeyStack.apiKey);
        });
      }

      // check if page is in focus
      let hasFocus = documentAlias.hasFocus();

      function onchange () {
        hasFocus = documentAlias.hasFocus();
        if (hasFocus) {
          exitedPage = false;
          resetInactivity();
        } else {
          if (isDevicePhone && !exitedPage) {
            exitedPage = true;
            HockeyStack.endSession();
          }
        }
      }

      setInterval(() => {
        if (hasFocus !== documentAlias.hasFocus()) onchange();
      }, 500);

      addEvent(windowAlias, 'message', event => {
        if (event.data && event.data.name === 'hockeystack-identify' && event.data.email) {
          HockeyStack.identify(event.data.email);
        } else if (event.data && event.data.name === 'hockeystack-onsubmit' && event.data.element && event.source !== window) {
          createAction('onsubmit', null, { element: event.data.element, eventSource: 'hockeystack-onsubmit' });
        } else if (event.data && event.data.name === 'hockeystack-identify-form' && event.data.value) {
          identifyThroughForm(event.data.value, null);
        } else if (event.data && event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
          if (autoIdentify) {
            const email = event.data.data.find(d => d.name === 'email').value;
            HockeyStack.identify(email);
          }
          createAction('onsubmit', null, { element: '#hsForm_' + event.data.id, eventSource: 'hsFormCallback' });
        } else if (event.data && event.data.meetingBookSucceeded) {
          if (autoIdentify) {
            const email = event.data.meetingsPayload.bookingResponse.postResponse.contact.email;
            HockeyStack.identify(email);
          }
          HockeyStack.goal('Meeting Booked on Website', { integration: 'HubSpot' });
        } else return;
      });

      addEvent(windowAlias, 'klaviyoForms', event => {
        const metadata = event.detail.metaData;
        if (!metadata) return;

        if (autoIdentify) {
          const email = metadata.$email;
          HockeyStack.identify(email);
        }
        HockeyStack.goal('Submit Klaviyo Form', { integration: 'Klaviyo', klaviyoFormId: event.detail.formId });
      });

      function identifyThroughForm (value, form) {
        const email = value.trim();

        if (debugMode) console.log('identifyThroughForm fired', email);
        
        let clickInfo = {};
        try {
          clickInfo = getClickInfo(form);
        } catch (err) {
          sendErrorsToServer(err, { operation: 'identifyThroughForm/getClickInfo' });
        }

        if (validateEmail(email)) {
          HockeyStack.identify(email);

          HockeyStack.goal('Identified from Form', {
            email: formatIdentity(email),
            ...JSON.parse(JSON.stringify(clickInfo))
          });
        }
      }

      function checkSubmitClickInForm (target) {
        const clickedButton = target.closest('button') || target.closest('a');

        if (!clickedButton) return;

        if (clickedButton.nodeName === 'A' && !clickedButton.className.includes('button')) return;

        if (debugMode) console.log('checkSubmitClickInForm fired', clickedButton);

        const form = target.closest('form');

        if (form) {
          if (debugMode) console.log('found form', form);

          const elements = form.elements;
          let isValid = true;

          for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (!element || element.type === 'hidden') continue;

            if (element.validity && !element.validity.valid) {
              isValid = false;
            }

            // identify if element is type email
            if (autoIdentify && element.type === 'email') {
              identifyThroughForm(element.value, form);
            }
          }

          // track submission if button is type submit or has submission classes
          const hasSubmissionClasses = !!clickedButton.className.match(/(cta |submit|submission|book\-demo)/g);
          const hasOnSubmitInActionLog = actionLog.find(action => action.action.actionType === 'onsubmit');

          if (
            isValid &&
            !hasOnSubmitInActionLog &&
            (clickedButton.type === 'submit' || hasSubmissionClasses)
          ) {
            let clickInfo = {};

            try {
              clickInfo = getClickInfo(form);
            } catch (err) {
              sendErrorsToServer(err, { operation: 'checkSubmitClickInForm/getClickInfo' });
            }

            const clickInfoObj = JSON.parse(JSON.stringify(clickInfo))

            createAction('onsubmit', null, { ...clickInfoObj, eventSource: 'checkSubmitClickInForm' });
          }
        } else if (autoIdentify) {
          const allInputs = [...document.querySelectorAll('input')];

          if (debugMode) console.log('found all inputs to search for email', allInputs);

          allInputs.forEach(input => {
            if (
              input.type !== 'email' &&
              !input.placeholder?.toLowerCase().includes('email') &&
              !input.name?.toLowerCase().includes('email')
            ) return;

            const email = input.value.trim();
            if (validateEmail(email)) HockeyStack.identify(email);
          });
        }
      }

      function onClickFunc (e) {
        if (!window.innerPageClick) return;
        resetInactivity();

        let obj;
        try {
          obj = getClickInfo(e.target);
          createAction('onclick', null, obj);
        } catch (err) {
          sendErrorsToServer(err, { operation: 'onClickFunc/getClickInfo' });
        }

        checkSubmitClickInForm(e.target);

        urlRefresh();
      };

      let scrollHeight = 0;

      function onScrollFunc (e) {
        resetInactivity();

        scrollHeight = Math.max(scrollHeight, window.scrollY, document.body.scrollTop, document.documentElement.scrollTop);
        if (scrollHeight > scrollMax) scrollMax = scrollHeight;
      };

      function onSubmitFunc (e) {
        resetInactivity();

        if (debugMode) console.log('onsubmit fired');

        const form = e.target;

        let clickInfo = {};
        try {
          clickInfo = getClickInfo(form);
        } catch (err) {
          sendErrorsToServer(err, { operation: 'onSubmitFunc/getClickInfo' });
        }

        if (clickInfo && clickInfo.element.startsWith('#hsForm_')) return;

        try {
          const elements = form.elements;
          for (let i = 0; i < elements.length; i++) {
            const element = elements[i];

            if (debugMode) console.log('form element', element.type, element.name, element.value);

            if (element.type === 'search' && elements.length <= 2) {
              const obj = { ...clickInfo, value: element.value };
              createAction('onsearch', null, obj);
              return;
            } else if (element.type === 'text' && (['s', 'q', 'k'].includes(element.name) || /search|query|keyword/i.test(element.name)) && elements.length <= 2) {
              const obj = { ...clickInfo, value: element.value };
              createAction('onsearch', null, obj);
              return;
            } else if (autoIdentify && ['email', 'text'].includes(element.type)) {
              identifyThroughForm(element.value, form);

              window.parent?.postMessage({ name: 'hockeystack-identify-form', value: element.value }, '*');
            }
          }

          if (e.target.querySelector('input[type=submit]') && /search/i.test(e.target.querySelector('input[type=submit]').value) && e.target.querySelectorAll('input[type=text]').length === 1) {
            const element = e.target.querySelector('input[type=text]');
            const obj = { ...clickInfo, value: element.value };
            createAction('onsearch', null, obj);
            return;
          }

          const clickInfoObj = JSON.parse(JSON.stringify(clickInfo));

          createAction('onsubmit', null, { ...clickInfoObj, eventSource: 'onSubmitFunc' });

          window.parent?.postMessage({ name: 'hockeystack-onsubmit', element: (clickInfo || {}).element }, '*');
        } catch (err) {
          sendErrorsToServer(err, { operation: 'onSubmitFunc' });
        }
      };

      addEvent(document, 'mouseover', () => {
        window.innerPageClick = true;
      });
      addEvent(document, 'mouseleave', () => {
        window.innerPageClick = false;
      });

      function resetInactivity () {
        if ((new Date().getTime()) - lastActiveDate.getTime() > (1000 * 60 * inactivityTime)) {
          isInactive = true;
          HockeyStack.endSession();
        }
        lastActiveDate = new Date();
        inactivityCounter = 0;

        if (isInactive) {
          setTimeout(() => {
            if (documentAlias.hasFocus()) HockeyStack.init(HockeyStack.apiKey);
          }, 5000);
        }
        isInactive = false;
      };

      addEvent(windowAlias, 'mousemove', resetInactivity);
      addEvent(windowAlias, 'mousedown', e => onClickFunc(e));
      addEvent(windowAlias, 'scroll', e => onScrollFunc(e));
      addEvent(windowAlias, 'keydown', resetInactivity);

      // track forms on page and window submissions
      formsOnPage = [...document.querySelectorAll('form')];
      formsOnPage.forEach(form => addEvent(form, 'submit', e => onSubmitFunc(e)));

      // workaround to dynamically added forms
      setInterval(() => {
        const newFormsOnPage = [...document.querySelectorAll('form')].filter(form => !formsOnPage.includes(form));
        newFormsOnPage.forEach(form => addEvent(form, 'submit', e => onSubmitFunc(e)));
        formsOnPage = formsOnPage.concat(newFormsOnPage);
      }, 5000);

      addEvent(windowAlias, 'submit', e => {
        if (formsOnPage.includes(e.target)) return;
        onSubmitFunc(e);
      });

      // track user inactivity
      const inactivityCheck = setInterval(() => {
        inactivityCounter++;
        if (inactivityCounter >= inactivityTime) inactivityEndSession();
      }, 60000);

      function inactivityEndSession () {
        clearInterval(inactivityCheck);
        isInactive = true;
        HockeyStack.endSession();
      }
    };

    HockeyStack.identify = (newIdentity, userProp) => {
      if (debugMode) console.log('Identify called with', newIdentity, userProp);

      if (typeof newIdentity === 'string') {
        newIdentity = formatIdentity(newIdentity);
        identity = newIdentity;
        userObject.identity = newIdentity;
        if (window.parent && window.parent.postMessage) {
          window.parent.postMessage({ name: 'hockeystack-identify', identity }, '*');
        }
      } else if (typeof newIdentity === 'object') {
        userObject.custom = Object.assign((userObject.custom || {}), newIdentity);
      }

      if (userProp && typeof userProp === 'object') {
        userObject.custom = Object.assign((userObject.custom || {}), userProp);
      }

      createAction('identify', null);
    };

    HockeyStack.addSharedProperty = ({ key, value, properties }) => {
      const sharedObject = { key, value, properties };

      if (debugMode) console.log('Shared property called with', JSON.stringify(sharedObject));

      try {
        const existingIndex = userObject.shared.findIndex(shared => shared.key === key);
        if (existingIndex !== -1) {
          userObject.shared.splice(existingIndex, 1, sharedObject);
        } else {
          userObject.shared.push(sharedObject);
        }
      } catch (err) {
        sendErrorsToServer(err, { operation: 'addSharedProperty', sharedObject });
      }
    };

    HockeyStack.goal = (actionName, actionProperties = {}, actionDate) => {
      if (debugMode) console.log('Goal called with', actionName, JSON.stringify(actionProperties), actionDate);
      const currentURL = windowAlias.location.origin + windowAlias.location.pathname + windowAlias.location.search;
      const actionObject = {
        actionName,
        actionProperties,
        actionType: 'custom',
        actionDate: actionDate ? new Date(actionDate).toISOString() : (isInactive ? new Date(lastActiveDate.getTime()).toISOString() : new Date().toISOString())
      };
      actionLog.push({ action: actionObject, url: currentURL.toLowerCase() });
    };

    // OTHER METHODS
    const addEvent = (element, type, listener) => {
      if (!['submit', 'change'].includes(type) && eventListenerList.includes(type)) return;

      if (debugMode) console.log('Adding event listener for', type, 'on', element);
      if (typeof element.addEventListener !== 'undefined') element.addEventListener(type, listener, false);
      else element.attachEvent('on' + type, listener);
      eventListenerList.push(type);
    };

    const urlRefresh = () => {
      // if page changed on its own, create an enter-page action
      if (getCurrentURL() !== globalCurrentPage) {
        const scrollDepth = (!hasVerticalScroll()) ? 100 : parseInt((scrollMax / (getDocHeight() - getViewportHeight())) * 100);
        createAction('scroll-depth', globalCurrentPage, scrollDepth);

        createAction('enter-page', null);
        sendActions('beacon');
      }

      globalCurrentPage = getCurrentURL();
    };

    setInterval(() => {
      urlRefresh();
    }, 500);

    const createAction = (actionType, URL, actionInfo) => {
      if (debugMode) console.log('Creating action', actionType, URL, JSON.stringify(actionInfo));

      const currentURL = URL || getCurrentURL();
      const localCurrentPage = globalCurrentPage;

      if (onlyIdentify && actionType !== 'identify') return;

      const actionObject = {
        actionType,
        actionDate: isInactive ? new Date(lastActiveDate.getTime()).toISOString() : new Date().toISOString()
      };

      if (actionType === 'onclick') {
        actionObject.actionElement = actionInfo.element;
        if (actionInfo.text) actionObject.actionText = actionInfo.text;
        if (actionInfo.url) {
          actionObject.actionUrl = actionInfo.url;

          if (actionInfo.url === currentURL && currentURL !== localCurrentPage) {
            createAction('onclick', localCurrentPage, actionInfo);
            return;
          }
        }
      } else if (actionType === 'onsearch') {
        actionObject.actionValue = actionInfo.value;
        actionObject.actionElement = actionInfo.element;
      } else if (actionType === 'scroll-depth') {
        actionObject.actionNumber = actionInfo || 100; // scroll-depth null bug
      } else if (actionType === 'onsubmit') {
        actionObject.actionElement = actionInfo.element;
      }

      if (!['enter-page', 'scroll-depth'].includes(actionType) && currentURL !== globalCurrentPage) {
        urlRefresh();
      }

      actionLog.push({ action: actionObject, url: currentURL.toLowerCase() });

      // send actions automatically on submit or identify
      if (actionType === 'onsubmit' || actionType === 'identify') {
        sendActions('beacon');
      }
    };

    const formatIdentity = identity => privacyMode ? SHA1(identity.toLowerCase()) : identity.toLowerCase();

    const sendActions = type => {
      if (isBot() || navigatorAlias.webdriver) return; // do not send it at all
      if (actionLog.length === 0) return;

      const actionLogCopy = JSON.parse(JSON.stringify(actionLog));

      const referrer = getReferrer(getCurrentURL(), document.referrer);

      const requestObj = { customerObject, userObject, actionLog: actionLogCopy, referrer };

      let success;
      if (type === 'beacon') success = sendReqViaBeacon(serverPath, requestObj);
      else success = sendReqViaXHR(serverPath, requestObj);

      if (success) actionLog.length = 0;
    };

    const sameSite = uri => {
      if (typeof uri === 'undefined') return false;

      let url;
      try {
        url = new URL(uri);
      } catch (err) {
        sendErrorsToServer(err, { operation: 'sameSite/newURL', uri });
        return false;
      }

      return windowAlias.location.hostname === url.hostname;
    };

    const getId = () => getCookie('hs_uuid') || generateUUID();

    const generateUUID = () => {
      let d = new Date().getTime();
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    };

    const validateEmail = email =>
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

    const setCookie = (cookieKey, value, days) => {
      const date = new Date();
      date.setDate(date.getDate() + days);
      const cookieValue = value + ((days == null) ? '' : '; expires=' + date.toUTCString()) + '; path=/;SameSite=Lax' + (crossDomain ? `;domain=${window.location.hostname}` : '');
      documentAlias.cookie = cookieKey + '=' + cookieValue;
    };

    const getCookie = cookieKey => {
      let i;
      let k;
      let v;
      const cookieArray = documentAlias.cookie.split(';');

      for (i = 0; i < cookieArray.length; i++) {
        k = cookieArray[i].substr(0, cookieArray[i].indexOf('='));
        v = cookieArray[i].substr(cookieArray[i].indexOf('=') + 1);
        k = k.replace(/^\s+|\s+$/g, '');

        if (k === cookieKey) return v;
      }
      return null;
    };

    const removeCookie = () => {
      document.cookie = `hs_uuid=; expires=${new Date('2020-01-01')}`;
      if (document.cookie.includes('hs_uuid')) document.cookie = 'hs_uuid=;';
    };

    const getReferrer = (pageUrl, referrerUrl) => {
      if (/(\?.*utm_.*=)/.test(pageUrl)) return pageUrl;
      else if (/((\?|&)ref=)/.test(pageUrl)) return pageUrl;
      else if (/((\?|&)(gclid|fbclid|msclkid)=)/.test(pageUrl)) return pageUrl;
      else if (referrerUrl !== '') return referrerUrl;
      else return '';
    };

    const isBot = () => {
      const userAgent = navigatorAlias.userAgent.toLowerCase();
      return (windowAlias.location.search.includes('disable_abtestingai') ||
          /(preview|funnelytics|crawl|hexometer|curl|lynx|ptst|nuhk|googlebot|googlesecurityscanner|gtmetrix|slurp|ask jeeves\/teoma|ia_archiver|google web preview|mediapartners-google|baiduspider|ezooms|yahooseeker|altavista|mercator|scooter|infoseek|ultraseek|lycos|wget|yadirectfetcher|magpie-crawler|nutch crawler|cms crawler|domnutch|netseer|digincore|fr-crawler|wesee|aliasio|bingpreview|headlesschrome|facebookexternalhit|facebookplatform|facebookexternalua|bot|crawler|sp(i|y)der|search|worm|fetch|nutch)/i.test(userAgent));
    };

    const getDevice = userAgent => /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(userAgent) ?
      'tablet' :
      /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/i.test(userAgent) ?
        'phone' :
        'desktop';

    const getUserProperties = () => ({
      language: navigatorAlias.language,
      platform: navigatorAlias.platform,
      uuid: (isFingerprint ? originalUUID : getId()),
      ...(isFingerprint) && {
        screen: {
          availHeight: windowAlias.screen.availHeight,
          height: windowAlias.screen.height,
          width: windowAlias.screen.width,
          depth: windowAlias.screen.colorDepth + windowAlias.screen.pixelDepth
        },
        mimeTypes: getMimeTypes(),
        plugins: getPlugins(),
        storageEnabled: (!!localStorage).toString() + !!indexedDB + navigatorAlias.cookieEnabled,
        otherInfo: navigatorAlias.hardwareConcurrency + navigatorAlias.javaEnabled()
      },
      identity,
      custom: {},
      shared: []
    });

    const identifyThroughURL = parameterName => {
      const urlParams = [...new URLSearchParams(window.location.search)];
      const identityMap = urlParams.find(param => {
        let isParamKeyMatch = false;

        const paramKeyFromURL = param[0].toLowerCase();
        if (parameterName) isParamKeyMatch = paramKeyFromURL === parameterName;
        else isParamKeyMatch = paramKeyFromURL.includes('email');

        if (isParamKeyMatch) {
          if (debugMode) console.log('Possible identify through URL: ', JSON.stringify(param));
          if (validateEmail(param[1])) return true;
        }

        return false;
      });
      if (identityMap && identityMap[1]) HockeyStack.identify(identityMap[1]);
    };

    const getMimeTypes = () => {
      let s = '';
      const mimeTypes = navigatorAlias.mimeTypes || [];
      for (const m of mimeTypes) { s += m.type; };
      return s;
    };

    const getPlugins = () => {
      let s = '';
      for (const p of navigator.plugins) { s += p.filename; }
      return s;
    };

    const getDocHeight = () => {
      const d = documentAlias;
      return Math.max(
        Math.max(d.body.scrollHeight, d.documentElement.scrollHeight),
        Math.max(d.body.offsetHeight, d.documentElement.offsetHeight),
        Math.max(d.body.clientHeight, d.documentElement.clientHeight)
      );
    };

    const getViewportHeight = () => {
      const d = documentAlias;
      return Math.min(
        Math.min(d.body.clientHeight, d.documentElement.clientHeight),
        Math.min(d.body.offsetHeight, d.documentElement.offsetHeight),
        window.innerHeight
      );
    };

    const hasVerticalScroll = () => {
      if (windowAlias.innerHeight) {
        return Math.max(documentAlias.body.scrollHeight, documentAlias.documentElement.scrollHeight) > windowAlias.innerHeight;
      } else {
        return documentAlias.documentElement.scrollHeight >
            documentAlias.documentElement.offsetHeight ||
            documentAlias.body.scrollHeight > documentAlias.body.offsetHeight;
      }
    };

    const getClickInfo = el => {
      const path = [];
      let text = '';
      let url = '';

      if (!el) return {};

      if (el.placeholder) text = el.placeholder;
      else if (el.innerText) {
        let innerText = el.innerText.replace(/\n/g, ' ');
        if (innerText.length > 103) innerText = innerText.substring(0, 100) + '...';
        text = innerText;
      } else if (el.tagName.toLowerCase() === 'input' && (el.type === 'button' || el.type === 'submit')) text = el.value;
      text = text.trim();

      if (el.src) url = el.src;
      else if (el.href && el.href !== '') url = el.href;
      url = url.trim();

      while (el.parentNode) {
        let t = el.tagName.toLowerCase();
        if (el.id) {
          t = '#' + el.id;
          path.unshift(t);
          break;
        } else {
          const isSVG = (el.nodeType === 1 && el.namespaceURI === 'http://www.w3.org/2000/svg');
          const className = (isSVG ? (el.className ? el.className.baseVal : el.getAttribute('class')) : [...el.classList].join(' ')).trim() || '';
          // remove spaces - escape colons - escape class names starting with digits
          if (className !== '') t += '.' + className.replace(/ +/g, '.').replace(/[:/]|\.[0-9]|\[|\]|%|~|=|&/g, '\\$&');

          const parentElement = el.parentElement;
          if (parentElement) {
            const siblings = parentElement.querySelectorAll(t);
            if (siblings.length > 1) {
              for (let i = 0; i < siblings.length; i++) {
                if (siblings[i] === el) {
                  t += ':nth-of-type(' + (i + 1) + ')';
                }
              }
            }
          }

          path.unshift(t);
          el = el.parentNode;
        }
      }
      const res = { element: path.join(' ') };
      if (text !== '') res.text = text;
      if (url !== '') res.url = url;
      return res;
    };

    HockeyStack.getClickInfo = getClickInfo;

    const SHA1 = msg => {
      const rotateLeft = (n, s) => (n << s) | (n >>> (32 - s));

      const cvtHex = val => {
        let str = '';
        let i;
        let v;
        for (i = 7; i >= 0; i--) {
          v = (val >>> (i * 4)) & 0x0f;
          str += v.toString(16);
        }
        return str;
      };

      const Utf8Encode = string => {
        string = string.replace(/\r\n/g, '\n');
        let utftext = '';
        for (let n = 0; n < string.length; n++) {
          const c = string.charCodeAt(n);
          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }
        return utftext;
      };

      let blockstart;
      let i, j;
      const W = new Array(80);
      let H0 = 0x67452301;
      let H1 = 0xEFCDAB89;
      let H2 = 0x98BADCFE;
      let H3 = 0x10325476;
      let H4 = 0xC3D2E1F0;
      let A, B, C, D, E;
      let temp;
      msg = Utf8Encode(msg);
      const msgLen = msg.length;
      const wordArray = [];

      for (i = 0; i < msgLen - 3; i += 4) {
        j = msg.charCodeAt(i) << 24 | msg.charCodeAt(i + 1) << 16 |
            msg.charCodeAt(i + 2) << 8 | msg.charCodeAt(i + 3);
        wordArray.push(j);
      }

      switch (msgLen % 4) {
        case 0:
          i = 0x080000000;
          break;
        case 1:
          i = msg.charCodeAt(msgLen - 1) << 24 | 0x0800000;
          break;
        case 2:
          i = msg.charCodeAt(msgLen - 2) << 24 | msg.charCodeAt(msgLen - 1) << 16 | 0x08000;
          break;
        case 3:
          i = msg.charCodeAt(msgLen - 3) << 24 | msg.charCodeAt(msgLen - 2) << 16 | msg.charCodeAt(msgLen - 1) << 8 | 0x80;
          break;
      }
      wordArray.push(i);
      while ((wordArray.length % 16) !== 14) wordArray.push(0);
      wordArray.push(msgLen >>> 29);
      wordArray.push((msgLen << 3) & 0x0ffffffff);
      for (blockstart = 0; blockstart < wordArray.length; blockstart += 16) {
        for (i = 0; i < 16; i++) W[i] = wordArray[blockstart + i];
        for (i = 16; i <= 79; i++) W[i] = rotateLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
        A = H0;
        B = H1;
        C = H2;
        D = H3;
        E = H4;
        for (i = 0; i <= 19; i++) {
          temp = (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
          E = D;
          D = C;
          C = rotateLeft(B, 30);
          B = A;
          A = temp;
        }
        for (i = 20; i <= 39; i++) {
          temp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
          E = D;
          D = C;
          C = rotateLeft(B, 30);
          B = A;
          A = temp;
        }
        for (i = 40; i <= 59; i++) {
          temp = (rotateLeft(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
          E = D;
          D = C;
          C = rotateLeft(B, 30);
          B = A;
          A = temp;
        }
        for (i = 60; i <= 79; i++) {
          temp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
          E = D;
          D = C;
          C = rotateLeft(B, 30);
          B = A;
          A = temp;
        }
        H0 = (H0 + A) & 0x0ffffffff;
        H1 = (H1 + B) & 0x0ffffffff;
        H2 = (H2 + C) & 0x0ffffffff;
        H3 = (H3 + D) & 0x0ffffffff;
        H4 = (H4 + E) & 0x0ffffffff;
      }
      temp = cvtHex(H0) + cvtHex(H1) + cvtHex(H2) + cvtHex(H3) + cvtHex(H4);
      return temp.toLowerCase();
    };

    const trackContentSchema = () => {
      const schemaScript = document.querySelector('script.yoast-schema-graph');
      if (schemaScript && schemaScript.innerText) {
        const articleSchema = JSON.parse(schemaScript.innerText)['@graph'].find(object => object['@type'] === 'Article');
        const breadcrumbListSchema = JSON.parse(schemaScript.innerText)['@graph'].find(object => object['@type'] === 'BreadcrumbList');
        const personSchema = JSON.parse(schemaScript.innerText)['@graph'].find(object => object['@type'] === 'Person');

        if (articleSchema) {
          HockeyStack.goal('View Content', {
            content_category: articleSchema.articleSection[0],
            content_author: (articleSchema.author && articleSchema.author.name) || (personSchema && personSchema.name),
            content_title: articleSchema.headline,
            content_url: new URL(articleSchema.mainEntityOfPage['@id']).pathname
          });
        } else if (breadcrumbListSchema && breadcrumbListSchema.itemListElement.some(item => item.name.toLowerCase().includes('blog'))) {
          HockeyStack.goal('View Content', {
            content_author: (personSchema && personSchema.name),
            content_title: document.title,
            content_url: window.location.pathname
          });
        }
      }
    };

    const trackGravityForms = () => {
      const identifyForm = emailInput => {
        const email = emailInput.value.trim();
        if (email) window.HockeyStack.identify(email);
      };

      const processGForms = () => {
        if (!(timeout > 5000 || typeof jQuery !== 'undefined')) {
          timeout += 50;
          setTimeout(processGForms, 50);
          return;
        }

        try {
          if (typeof jQuery === 'function') {
            jQuery(document).on('gform_page_loaded', function (event, formId, currentPage) {
              const emailInput = document.querySelector(`#gform_${formId} input[type="email"]`);

              window.HockeyStack.goal('Move to New Page', {
                formId,
                page: currentPage,
                integration: 'Gravity Forms'
              });

              if (emailInput) identifyForm(emailInput);
            });
          }

          window.addEventListener('click', event => {
            const form = event.target.closest('form');
            const isGravityForm = form && form.id && form.id.includes('gform');
            if (!isGravityForm) return;

            const formId = form.id.replace('gform_', '');
            if (window[`gf_submitting_${formId}`]) {
              window.HockeyStack.goal('Submit Gravity Form', {
                formId,
                integration: 'Gravity Forms'
              });

              const emailInput = form.querySelector('input[type="email"]');
              if (emailInput) identifyForm(emailInput);
            }
          });
        } catch (error) {
          sendErrorsToServer(error, { operation: 'processGForms' });
        }
      };

      let timeout = 0;
      processGForms();
    };

    if (apiKey) HockeyStack.init(apiKey);

    (() => {
      const createScript = script => {
        if (!apiKey) return;
        if (document.querySelector(`script[src*="${script}"]`)) return;

        if (debugMode) console.log('Creating script', script);

        const newscript = document.createElement('script');
        newscript.src = script;
        newscript.async = 1;
        newscript.dataset.apikey = apiKey;
        newscript.dataset.cookieless = 1;
        newscript.dataset.autoGenerated = true;
        document.getElementsByTagName('head')[0].append(newscript);
      };

      const loadScripts = () => {
        const hasArcadeInstalled = !!document.querySelector('iframe[src*="demo.arcade.software"]');
        const hasHeyflowInstalled = !!document.querySelector('script[src*="heyflow.app"]');
        const has6SenseInstalled = !!document.querySelector('script[src*="j.6sc.co/6si.min.js"]');
        const hasDemandbaseInstalled = !!document.querySelector('script[src*="tag.demandbase.com"]');
        const hasCalendlyInstalled = !!(new URLSearchParams(window.location.search)).get('event_type_uuid');
        const hasQualifiedInstalled = !!(document.querySelector('script[src*="qualified"]'));
        const hasZoomInfoChatInstalled = !!(document.querySelector('script[src*="insent.ai"]'));
        const hasNavatticInstalled = !!(document.querySelector('script[src*="js.navattic.com"]'));
        const hasDriftInstalled = !!(document.querySelector('script[src*="js.driftt.com"]'));
        const hasPardotInstalled = !!(document.querySelector('script[src*="pi.pardot.com"]'));
        const hasMktoForms2Installed = !!(window.MktoForms2);

        if (hasArcadeInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-arcade.min.js');
        if (hasHeyflowInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-heyflow.min.js');
        if (has6SenseInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-6sense.min.js');
        if (hasDemandbaseInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-demandbase.min.js');
        if (hasCalendlyInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-calendly.min.js');
        if (hasQualifiedInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-qualified.min.js');
        if (hasZoomInfoChatInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-zoominfo-chat.min.js');
        if (hasNavatticInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-navattic.min.js');
        if (hasDriftInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-drift.min.js');
        if (hasMktoForms2Installed) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-marketo-forms.min.js');
        if (hasPardotInstalled) createScript('https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack-pardot.min.js');
      };

      if (document.readyState === 'complete' || document.readyState === 'interactive') loadScripts();
      window.addEventListener('load', () => {
        loadScripts();
        let timeout = 0;
        const loadScriptTimeout = () => {
          setTimeout(() => {
            if (timeout === 50) return;
            timeout++;
            loadScripts();
            loadScriptTimeout();
          }, 200);
        };
        loadScriptTimeout();
      });
    })();

    return HockeyStack;
  } catch (err) {
    sendErrorsToServer(err, { operation: 'general' });
  }
})();

if (window.hockeystackQueue) window.hockeystackQueue.forEach(callback => callback());
